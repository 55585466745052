import { decodeJwt } from 'lib/utils';

export const getUserEmail = () => {
  const token = localStorage.getItem('authToken');

  if (!token) {
    return '';
  }

  // @ts-expect-error at this moment we always know that token is valid
  return decodeJwt(token.toString()).email;
};

export const getUserRoles = () => {
  if (typeof window !== 'undefined') {
    const token = localStorage.getItem('authToken');

    if (!token) {
      return '';
    }

    // @ts-expect-error at this moment we always know that token is valid
    return decodeJwt(token.toString()).roles || [];
  }

  return '';
};
