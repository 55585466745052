import Image from 'next/image';
import styles from './loader.module.scss';

function ReplaceLoader() {
  return (
    <Image
      src="/loader.svg"
      width={40}
      height={40}
      alt="Loading"
      className={styles.replaceLoader}
    />
  );
}

export default ReplaceLoader;
